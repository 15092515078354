import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AbstractPanoWebService } from '../abstract-pano-web.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PanoArticleList } from '../../models/article.model';
import { FidjiAppConfigsManagerService } from '@immanens-com/fidjiapimodule2';
@Injectable()
export class ArticlePanoWebService extends AbstractPanoWebService {

    constructor(protected http: HttpClient,
        protected sanitizer: DomSanitizer,
        protected configsMng: FidjiAppConfigsManagerService
    ) {
        super(http, sanitizer, configsMng);
    }

    public getArticlesByCategory(cat_id: number, limit: number = 0, has_image: number = null, sort: string = '', desc: string = '')
        : Observable<PanoArticleList> {
        const params: { [key: string]: any } = {};
        params.category_id = cat_id;
        if (limit > 0) {
            params.limit = limit;
        }
        if (has_image !== null) {
            params.has_image = has_image;
        }
        if (sort.length) {
            params.sort = sort;
        }
        if (desc.length) {
            params.desc = desc;
        }
        params.min_char = 300; /// filter on articles, we ignore articles of less than 300 characters

        return this.get('/article', params);
    }

    public getAFPArticles(limit: number = 0) {
        const params: { [key: string]: any } = {};
        params.origin = 'AFP';

        params.sort = 'article.createdAt';
        params.desc = 'article.createdAt';
        if (limit > 0) {
            params.limit = limit;
        }

        return this.get('/article', params);
    }

    public getAFPSportArticles(limit: number = 0) {
        const params: { [key: string]: any } = {};
        params.origin = 'AFPSPORT';

        params.sort = 'article.createdAt';
        params.desc = 'article.createdAt';
        if (limit > 0) {
            params.limit = limit;
        }

        return this.get('/article', params);
    }

    public getArticlesByIssue(issue_id: number, limit: number, offset: number): Observable<PanoArticleList> {
        const params: { [key: string]: any } = {};
        params.issue_id = issue_id;
        if (limit > 0) {
            params.limit = limit;
        }
        if (offset > 0) {
            params.offset = offset;
        }
        params.min_char = 300;
        return this.get('/article', params);
    }

    public getArticlesByPhenixIssue(issue_id: number, limit: number, offset: number): Observable<PanoArticleList> {
        const params: { [key: string]: any } = {};
        params.phenix_issue_id = issue_id;
        if (limit > 0) {
            params.limit = limit;
        }
        if (offset > 0) {
            params.offset = offset;
        }
        params.min_char = 300;
        return this.get('/article', params);
    }

    public getIssueSummary(issue_id: number) {
        return this.get('/issue/' + issue_id + '/summary');
    }
}
