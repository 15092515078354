import { BrowserAnimationsModule  } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import localeFr from '@angular/common/locales/fr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './not-found/not-found.component';
import {SharedModule} from './shared/shared.module';
import { CoreModule } from './core/core.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import { NoConnectionComponent } from './no-connection/no-connection.component';
import { IMModalModule } from './shared/modal/modal.module';
import { ConnectionGuard } from './core/guards/connection.guard';
import { FidjiApiModule2, FidjiAppSettingsManagerService } from '@immanens-com/fidjiapimodule2';
import { AppLoadConfigsComponent } from './app-load-configs.component';
import { environment } from '../environments/environment';
import * as $ from 'jquery';
import { CustomTranslateLoader } from './custom-translate-loader';

registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    NoConnectionComponent,
    AppLoadConfigsComponent
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr' },
    ConnectionGuard
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    CoreModule,
    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useClass: CustomTranslateLoader,
            deps: [HttpClient, FidjiAppSettingsManagerService]
        },
        useDefaultLang: false
    }),
    IMModalModule.forRoot(),
    FidjiApiModule2.forRoot(null, environment.all_settings),
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
