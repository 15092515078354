<section class="grid-loading otherCatArticle" [@fadeInOut]="getFadeState()" *ngIf="!isFrontCat">
    <article *ngFor="let i of array" class="document">
      <div class="pano-loading {{height}}" [ngStyle]="backgroundColorString()"></div>
      <h2 class="pano-loading" *ngIf="height != 'medium'" [ngStyle]="backgroundColorString()"></h2>
      <p class="pano-loading" [ngStyle]="backgroundColorString()"></p>
    </article>
</section>

<section class="grid-loading frontCatArticle" [@fadeInOut]="getFadeState()" *ngIf="isFrontCat">
    <article *ngFor="let i of (array | slice:0:2)" class="document">
      <div class="pano-loading {{height}}"  [ngStyle]="backgroundColorString()"></div>
      <h2 class="pano-loading" [ngStyle]="backgroundColorString()"></h2>
      <p class="pano-loading" [ngStyle]="backgroundColorString()"></p>
    </article>
</section>

