import { Injectable } from '@angular/core';
import { IMDomService } from './dom.service';

@Injectable({
    providedIn: 'root'
})
export class IMModalService {
    // https://itnext.io/angular-create-your-own-modal-boxes-20bb663084a1
    constructor(private domService: IMDomService) { }

    private modalElementId = 'modal-outlet';
    private overlayElementId = 'modal-overlay';

    init(component: any, inputs: object, outputs: object) {
        const componentConfig = {
            inputs: inputs,
            outputs: outputs
        };
        try {
            this.domService.appendComponentTo(this.modalElementId, component, componentConfig);
            document.getElementById(this.modalElementId).className = 'show';
            document.getElementById(this.overlayElementId).className = 'show';
            document.body.classList.add('modal-open');
        } catch (e) {
            console.error(`Don't forget to add 'modal-outlet' to the DOM before calling modal init`, e);
        }
    }

    destroy() {
        document.getElementById(this.modalElementId).className = 'hidden';
        document.getElementById(this.overlayElementId).className = 'hidden';
        document.body.classList.remove('modal-open');
        this.domService.removeComponent();
    }
}
