import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observer, Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { PV5_TOKEN_HEADER } from '../utilities/defines';
import { environment } from '../../../environments/environment';
import { FidjiAppSettingsManagerService, HTTPMethods, objectIsEmpty } from '@immanens-com/fidjiapimodule2';


export interface Pv5Request {
    observer: Observer<any>;
    url: string;
    body?: object;
    authentified: boolean;
    method: HTTPMethods;
    token: string;
}

/** Different token management **/

@Injectable()
export class AbstractPV5WebService {

    protected requestsQueue: Pv5Request[] = [];

    protected requestsArePending = true; // We don't launch requests on init.

    constructor(
        protected http: HttpClient,
        protected sanitizer: DomSanitizer,
        protected appSettingsMng: FidjiAppSettingsManagerService
    ) {
        this.relaunchService('json');
    }

    protected relaunchService(withResponseType) {
        if (this.allIsOkForService()) {
            this.requestsArePending = false;
            this.continueLaunchingRequests(withResponseType);
        }
    }

    private allIsOkForService(): boolean {
        return this.requestsArePending === true;
    }

    protected get(route: string, parameters: object = null, anonymous = true, token = null, responseType = 'json'): Observable<any> {

        const url = this.constructURL(route, parameters);

        return Observable.create((observer: Observer<any>) => {
            const request = {
                url: url,
                observer: observer,
                authentified: anonymous,
                method: HTTPMethods.GET,
                token: token
            };

            this.requestsQueue.push(request);

            if (this.requestsArePending === true) {
                this.relaunchService(responseType);
            }

            return;

        });
    }

    /**
     * This function prepare the request and add it to the requestQueue
     */
    protected post(route: string, body: object = null, anonymous = true, token = null, responseType = 'json', constructUrl = true): Observable<any> {

        let url;
        if (constructUrl) {
            url = this.constructURL(route, {});
        } else {
            url = route;
        }

        return Observable.create((observer: Observer<any>) => {
            const request = {
                url: url,
                observer: observer,
                body: body,
                authentified: anonymous,
                method: HTTPMethods.POST,
                token: token
            };

            this.requestsQueue.push(request);

            if (this.requestsArePending === true) {
                this.relaunchService(responseType);
            }

            return;
        });
    }

    /**
     * This function have a good name :)
     */
    private constructURL(route: string, parameters: object): string {

        let finalURL = this.appSettingsMng.appSettings.pv5_api_url + route;

        if (!objectIsEmpty(parameters)) {

            finalURL += '?';

            for (const key of Object.keys(parameters)) {

                const value = parameters[key];

                if (parameters[key] != null) { // Avoid null or undefined parameters

                    if (Array.isArray(parameters[key])) {
                        value.forEach((v) => {
                            finalURL += (finalURL.substr(-1) !== '?' ? '&' : '') + `${key}[]=${v}`;
                        });
                    } else {
                        finalURL += (finalURL.substr(-1) !== '?' ? '&' : '') + `${key}=${parameters[key]}`;
                    }
                }
            }
        }

        return finalURL;
    }

    /**
     * Recursive function.
     * Stop when requestArePending is true. Relaunch by tokenIsSetChange observer.
     * This function get the first entry of the RequestQueue array and verify that all the context is good for launching request
     * if no problems are detected, we launch the request and move to the next request in the queue
     */
    private async continueLaunchingRequests(withResponseType) {
        while (this.requestsArePending === false) { // it's a deamon !
            if (this.requestsQueue.length === 0) {
                this.requestsArePending = true;
                return;
            }

            const request = this.requestsQueue.shift(); // Get the first element of the array
            // if (!this.tokenManager.tokenIsSet) { // We don't have any token
            //     this.requestsQueue.push(request); // Request will be launch later
            //     this.requestsArePending = true;
            //     return; // we don't launch request anymore since a new token is set
            // }

            // const token = await this.tokenManager.getValidToken();

            // if (request.authentified && !token.isAuthentified) { // We can't launch this request.
            //     // Request is drop
            //     request.observer.error(Error('Token is not authentified, no rights to do this request'));
            // } else { // All is good
            this.launchRequest(request, withResponseType); // Launch request and continue
            // }
        }

    }

    /**
     * Launch request with current token and appropriate headers and complete
     * Manage errors with manageErrors method
     */
    private async launchRequest(request: Pv5Request, responseType) {

        const httpOptions = {
            headers: new HttpHeaders(),
            responseType: responseType
        };

        ////////////////////////////////////// TODO ///////////////////////////////////////////
        if (request.token) {
            httpOptions.headers = httpOptions.headers.append(PV5_TOKEN_HEADER, request.token);
        }
        //     httpOptions.headers = httpOptions.headers.append(USER_TOKEN_HEADER, token.token);
        // }
        if (request.method === HTTPMethods.GET) {
            return this.http
                .get(request.url, httpOptions)
                .subscribe((data) => request.observer.next(data),
                    error => this.manageErrors(error, request),
                    () => request.observer.complete());
        }

        if (request.method === HTTPMethods.POST) {
            return this.http
                .post(request.url, request.body, httpOptions)
                .subscribe((data) => request.observer.next(data),
                    error => this.manageErrors(error, request),
                    () => request.observer.complete());
        }
    }

    private manageErrors(error: HttpErrorResponse, request: Pv5Request): void {
        if (error.status === 401 || error.status === 403) { // Unauthorized
            request.observer.error(error);
            return;
        } else if (error.status === 405 || error.status === 404) { // Not Found
            request.observer.error(error); // request is drop
            return;
        } else if (error.status === 500 || error.status === 540) { // Serveur malfunction
            request.observer.error(error); // send error
            return;
        } else if (error.status === 0 || error.status === 408 || error.status === 410) { // No server connection or time-out
            request.observer.error(error);
            return;
        } else {
            request.observer.error(error); // send unknown error
        }
    }
}
