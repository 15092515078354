<main class="container" *ngIf="appIsReady; else app_not_ready">
  
  <div class='routeContainer'>
    <router-outlet></router-outlet>
  </div>
  
</main>
<ng-container *ngIf="!isMobile && router.url.indexOf('advanced-search') === -1"><app-footer ></app-footer></ng-container>
<modal-outlet></modal-outlet>

<ng-template #app_not_ready>
  <div class="content-loader">
    <section class="hollowLoader">
      <div class="largeBox"></div>
      <div class="smallBox"></div>
    </section>
  </div>
  <!--   Waiting application settings -->
</ng-template>
