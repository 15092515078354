import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { WindowRef } from './core/utilities/window-ref/window-ref';
import { Subscription } from 'rxjs';
import { FidjiAppConfigsManagerService, FidjiQueryManagerService, FidjiTokenManagerService, FidjiUserManagerService } from '@immanens-com/fidjiapimodule2';
import { PreviousRouteService } from './core/services/previous-route/previous-route.service';
import { isInDev } from './core/utilities/tools';


// ce commentaire est une simple modification de fichier pour que Jenkins detecte bien ce put*** de commit !
// Et encore un (le problème c'est quand on change que package.json, jenkins ne détecte pas juste ce changement :/ )

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {

    public appIsReady = false;

    public currentActiveRoute: string;

    private subs = new Subscription();

    public isMobile;

    constructor(
        private tokenManager: FidjiTokenManagerService,
        public activatedRoute: ActivatedRoute,
        public router: Router,
        translate: TranslateService,
        private winRef: WindowRef,
        public configsMng: FidjiAppConfigsManagerService,
        private queryMng: FidjiQueryManagerService,
        private userMng: FidjiUserManagerService,
        private previousRoute: PreviousRouteService
    ) {
        this.subs.add(
            this.configsMng.appConfigsChange.subscribe(configIsReady => {
                if (configIsReady) {
                    // this.appIsReady = true;
                    // this language will be used as a fallback when a translation isn't found in the current language
                    translate.setDefaultLang(this.configsMng.getConfigValue('defaultLang'));

                    // the lang to use, if the lang isn't available, it will use the current loader to get them
                    translate.use(this.configsMng.getConfigValue('defaultLang'));
                }
            })
        );
        this.subscribeToTokenInUrl();
        this.subscribeToTokenIsSetChange();


        this.subs.add(
            this.router.events.subscribe((e) => {
                if (e instanceof NavigationEnd) {
                    this.currentActiveRoute = e.url;
                }
            })
        );

        this.isMobile = (this.winRef.nativeWindow.webkit || this.winRef.nativeWindow.ePresseAndroid);
    }

    subscribeToTokenInUrl() {
        this.subs.add(
            this.activatedRoute.queryParams.subscribe(async queryParams => {
                if (queryParams['x-user-token']) {
                    await this.userMng.logoutUser();
                    const newParams = await this.queryMng.handleQuery(queryParams);
                    // If no token, stop here
                    if (newParams) {
                        this.router.navigate([], {
                            relativeTo: this.activatedRoute,
                            queryParams: newParams,
                            queryParamsHandling: 'merge'
                        });
                    }

                    this.userMng.connected$.subscribe(connected => {
                        if (!connected && queryParams['x-user-token']) { // refresh token
                            // tslint:disable-next-line: max-line-length
                            if (!isInDev()) {
                                location.href = 'https://epresse.fr/internaute/refresh/token?callback=' + encodeURIComponent(window.location.href.split('?')[0]);
                            } else {
                                location.href = 'https://beta.epresse.fr/internaute/refresh/token?callback=' + encodeURIComponent(window.location.href.split('?')[0]);
                            }
                        }
                    });
                }
            })
        );
    }

    /**
     * Need this function on app init cause the app is blocked
     * since the token is correctly set.
     * A spinner is shown over the view for mention to user we don't have a token
     */
    subscribeToTokenIsSetChange() {
        this.subs.add(
            this.tokenManager.tokenIsSetChange.subscribe(async _ => {
                this.appIsReady = true;
            })
        );
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
