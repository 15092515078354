import { Meta } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';

/**
 * Class with usefull functions
 */
export class Tools {

    public static slugify(string) {
        const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
        const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnooooooooprrsssssttuuuuuuuuuwxyyzzz------';
        const p = new RegExp(a.split('').join('|'), 'g');

        return string.toString().toLowerCase()
            .replace(/\s+/g, '-') // Replace spaces with -
            .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
            .replace(/&/g, '-and-') // Replace & with 'and'
            .replace(/'/, '-')
            .replace(/[^\w\-]+/g, '') // Remove all non-word characters
            .replace(/\-\-+/g, '-') // Replace multiple - with single -
            .replace(/^-+/, '') // Trim - from start of text
            .replace(/-+$/, ''); // Trim - from end of text
    }

    public static updateMetaDescription(metaTag: Meta, HTMLString: string) {
        const parser = new DOMParser();
        const htmlContent = parser.parseFromString(HTMLString, 'text/html');
        const heading = htmlContent.getElementsByClassName('heading')[0];
        if (heading && /^\s*\n*\r*$/.test(heading.innerHTML)) {
            const pTags = htmlContent.getElementsByTagName('p');
            let idx = 0;
            let pTag = pTags[idx];
            while (idx < pTags.length) {
                if (/^\s*\n*\r*$/.test(pTag.innerHTML)) {
                    idx++;
                    pTag = pTags[idx];
                } else {
                    if (metaTag.getTag('name=description')) {
                        metaTag.updateTag({ name: 'description', content: pTag.innerText });
                    } else {
                        metaTag.addTag({ name: 'description', content: pTag.innerText }, true);
                    }
                    break;
                }
            }
        }
    }

    public static addTwitterTag(metaTag: Meta, tagName: string, tagContent: string) {
        if (metaTag.getTag(`name='` + tagName + `'`)) {
            metaTag.updateTag({ name: tagName, content: tagContent });
        } else {
            metaTag.addTag({ name: tagName, content: tagContent }, true);
        }
    }

    public static addFacebookLinkedinTag(metaTag: Meta, tagProperty: string, tagContent: string) {
        if (metaTag.getTag(`property='` + tagProperty + `'`)) {
            metaTag.updateTag({ property: tagProperty, content: tagContent });
        } else {
            metaTag.addTag({ property: tagProperty, content: tagContent }, true);
        }
    }
}

export function isInDev() {
    return window.location.href.indexOf('panofront-dev.immanens.com') !== -1
        || window.location.href.indexOf('panofront-beta.immanens.com') !== -1
        || window.location.href.indexOf('localhost') !== -1;

}
