import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {IMDomService} from './dom.service';
import {IMModalService} from './modal.service';
import {IMModalOutletComponent} from './modal-outlet/modal-outlet.component';

/**
 * This module holds a component outlet and a service that helps with displaying a modal.
 * Use the ModalOutlet component inside your application template and send a templateRef to the service
 * in order to display a template like a modal
 *
 * This service have to be imported with the forRoot() method inside
 * the main module of an application.
 */
@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [IMModalOutletComponent],
  exports: [IMModalOutletComponent]
})
export class IMModalModule {
  static forRoot(): ModuleWithProviders<IMModalModule> {
    return {
      ngModule: IMModalModule,
      providers: [
        IMDomService,
        IMModalService
      ]
    }
  }
}
