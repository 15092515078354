import { Injectable } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { WindowRef } from '../../utilities/window-ref/window-ref';

@Injectable()
export class NativeActions {

    private _window;
    constructor(
        private winRef: WindowRef
    ) {
        this._window = this.winRef.nativeWindow;
    }

    openArticle(articlyURL: string, mediaTitle: string, articleTitle: string, articleChapo: string) {
        const jsonObj = { articlyURL: articlyURL, mediaTitle: mediaTitle, articleTitle: articleTitle, articleChapo: articleChapo };
        if (this._window.webkit != null) {
            // this._window.webkit.messageHandlers.doAction.postMessage('openArticle', JSON.stringify(jsonObj));
            this._window.webkit.messageHandlers.openArticle.postMessage(JSON.stringify(jsonObj));
            return;
        }
        if (this._window.ePresseAndroid != null) {
            this._window.ePresseAndroid.doAction('openArticle', JSON.stringify(jsonObj));
        }
    }

    openTitle(articlyURL: string, issueId: number, hasRight: boolean, error: string) {
        const jsonObj = { articlyURL: articlyURL, issueId: issueId, hasRight: hasRight, error: error };
        if (this._window.webkit != null) {
            // this._window.webkit.messageHandlers.doAction.postMessage('openTitle', JSON.stringify(jsonObj));
            this._window.webkit.messageHandlers.openTitle.postMessage(JSON.stringify(jsonObj));
            return;
        }
        if (this._window.ePresseAndroid != null) {
            this._window.ePresseAndroid.doAction('openTitle', JSON.stringify(jsonObj));
        }
    }

    lostConnexion(url: string) {
        const jsonObj = { url: url };
        if (this._window.webkit != null) {
            // this._window.webkit.messageHandlers.doAction.postMessage('lostConnexion', JSON.stringify(jsonObj));
            this._window.webkit.messageHandlers.lostConnexion.postMessage(JSON.stringify(jsonObj));
            return;
        }
        if (this._window.ePresseAndroid != null) {
            this._window.ePresseAndroid.doAction('lostConnexion', JSON.stringify(jsonObj));
        }
    }

    updateTab(tabTitle: string) {
        const jsonObj = { tabTitle: tabTitle };
        if (this._window.webkit != null) {
            // this._window.webkit.messageHandlers.doAction.postMessage('updateTab', JSON.stringify(jsonObj));
            this._window.webkit.messageHandlers.updateTab.postMessage(JSON.stringify(jsonObj));
            return;
        }
        if (this._window.ePresseAndroid != null) {
            this._window.ePresseAndroid.doAction('updateTab', JSON.stringify(jsonObj));
        }
    }

    updateRight(issueId: number, publicationId: number) {
        const jsonObj = { issueId: issueId, publicationId: publicationId };
        if (this._window.webkit != null) {
            // this._window.webkit.messageHandlers.doAction.postMessage('updateRight', JSON.stringify(jsonObj));
            this._window.webkit.messageHandlers.updateRight.postMessage(JSON.stringify(jsonObj));
            return;
        }
        if (this._window.ePresseAndroid != null) {
            this._window.ePresseAndroid.doAction('updateRight', JSON.stringify(jsonObj));
        }
    }
}
