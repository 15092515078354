import {Component} from '@angular/core';
import {IMModalService} from '../modal.service';

@Component({
  selector: 'modal-outlet',
  templateUrl: './modal-outlet.component.html',
  styleUrls: ['./modal-outlet.component.scss']
})
export class IMModalOutletComponent {

  constructor(private modalService: IMModalService) {}

  removeModal() {
    this.modalService.destroy();
  }
}
