import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {PanoCategory} from '../../models/pano-category.model';
import {map, share} from 'rxjs/operators';
import { PanoCategoryWebService } from './pano-category-web.service';

@Injectable()
export class PanoCategoryControllerService {

  constructor(private categoryWS: PanoCategoryWebService) { }

  public getCategoriesList(): Observable<PanoCategory[]> {
    return this.categoryWS.categoriesList().pipe(
      map((categoriesList) => {
          return categoriesList.categories;
      }),
      share()
    );
  }

  public categoryById(id: number): Observable<PanoCategory> {
    return this.categoryWS.getCategory(id).pipe(share());
  }
}
