import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TopBarComponent} from './top-bar/top-bar.component';
import {RouterModule} from '@angular/router';
import {HttpClientModule} from '@angular/common/http';
import {CategoryShelfComponent} from './category-view/category-shelf/category-shelf.component';
import {TranslateModule} from '@ngx-translate/core';
import { ExtractGridComponent } from './extract-list/extract-grid/extract-grid.component';
import { AFPCoverflowComponent } from './coverflow/afp-coverflow/afp-coverflow.component';
import { FooterComponent } from './footer/footer.component';
import { ExtractThumbnailComponent } from './article-view/extract-thumbnail/extract-thumbnail.component';
import { FrontExtractGridComponent } from './extract-list/front-extract-grid/front-extract-grid.component';
import { IssueTocComponent } from './extract-list/issue-toc/issue-toc.component';
import { DragScrollModule } from 'ngx-drag-scroll';
import { SearchComponent } from './search-modal/search/search.component';
import { IMModalModule } from './modal/modal.module';
import { DatePipe } from '@angular/common';
import { LazyImageComponent } from './lazy-image/lazy-image.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { LoadingGridComponent } from './loading/grid/loading-grid.component';
import { LoadingCoverflowComponent } from './loading/coverflow/loading-coverflow.component';
import { LoadingArticleComponent } from './loading/loading-article/loading-article.component';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        RouterModule,
        TranslateModule.forChild(),
        DragScrollModule,
        IMModalModule.forRoot(),
        LazyLoadImageModule
    ],
    declarations: [
        TopBarComponent,
        ExtractGridComponent,
        FrontExtractGridComponent,
        ExtractThumbnailComponent,
        CategoryShelfComponent,
        AFPCoverflowComponent,
        FooterComponent,
        IssueTocComponent,
        SearchComponent,
        LazyImageComponent,
        LoadingGridComponent,
        LoadingCoverflowComponent,
        LoadingArticleComponent
    ],
    exports: [
        HttpClientModule,
        RouterModule,
        TopBarComponent,
        ExtractGridComponent,
        FrontExtractGridComponent,
        ExtractThumbnailComponent,
        CategoryShelfComponent,
        AFPCoverflowComponent,
        TranslateModule,
        FooterComponent,
        IssueTocComponent,
        IMModalModule,
        LoadingGridComponent,
        LoadingCoverflowComponent,
        LoadingArticleComponent
    ],
    providers: [DatePipe]
})
export class SharedModule {}
