// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
    production: false,
    enableServiceWorking: false, // change for android at build time
    all_settings: [
      {
        app_id: 287,
        host: ['localhost:4400', 'panofront-beta.immanens.com'],
        app_settings: {
          // device_description and device_os are set in AppSettingsManager
          device_description: '', // stop using window.navigator.userAgent here, it doesn't work
          device_os: 'browser', // stop using window.navigator.appVersion here, it doesn't work
          app_version: '',
          angular_version: '2.4.0', // Edit it for every modification to invalidate cache
          phenix_url: 'https://phenix2-epresse.immanens.com/',
          phenix_api_version: 1,
          // pv5_api_url: 'https://pressview5.immanens.com/api',
          pv5_api_url: 'https://panorama-pv5.immanens.com/api',
          // stream
          app_id: 287,
          phenix_api_key: '4b48-4191-cb92-d52f', // '554d-f177-3f0a-4d1c',
          device_token: '',
          force_device_app: false,
        }
      },
      {
        app_id: 285,
        // host: [],
        app_settings: {
          // device_description and device_os are set in AppSettingsManager
          device_description: '', // stop using window.navigator.userAgent here, it doesn't work
          device_os: 'browser', // stop using window.navigator.appVersion here, it doesn't work
          app_version: '',
          angular_version: '2.4.0', // Edit it for every modification to invalidate cache
          phenix_url: 'https://phenix2-epresse.immanens.com/',
          phenix_api_version: 1,
          pv5_api_url: 'https://panorama-pv5.immanens.com/api',
          // IOS
          app_id: 285,
          phenix_api_key: 'ff06-27ad-8848-3757',
          device_token: '',
          force_device_app: false,
        }
      },
      {
        app_id: 286,
        // host: [],
        app_settings: {
          // device_description and device_os are set in AppSettingsManager
          device_description: '', // stop using window.navigator.userAgent here, it doesn't work
          device_os: 'browser', // stop using window.navigator.appVersion here, it doesn't work
          app_version: '',
          angular_version: '2.4.0', // Edit it for every modification to invalidate cache
          phenix_url: 'https://phenix2-epresse.immanens.com/',
          phenix_api_version: 1,
          pv5_api_url: 'https://panorama-pv5.immanens.com/api',
          // Android
          app_id: 286,
          phenix_api_key: '4321dsfkljjx234sd9547xx',
          device_token: '',
          force_device_app: false,
        }
      }
    ]
};
// http://panorama-dev.immanens.com/api
