import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FidjiAppSettingsManagerService } from '@immanens-com/fidjiapimodule2';
import packageJson from '../../package.json';

export class CustomTranslateLoader implements TranslateLoader {

    private versionTranslate: string = packageJson.version_translate_custom;

    constructor(
        private http: HttpClient,
        private appSettings: FidjiAppSettingsManagerService
    ) { }

    /**
     * this function is called only after application has been initialized (in app.component => manageTranslationService),
     * so `this.appSettingsMng?.appSettings?.app_id` should no null !
     * @param lang lang to get translate json file
     * @returns observable with correct translate json file
     */
    getTranslation(lang: string): Observable<any> {
        if (this.appSettings?.appSettings?.app_id) {
            return this.http.get(
                './assets/i18n/' + lang + '-' + this.versionTranslate + '.json'
            ).pipe(
                catchError(() => {
                    return of('');
                })
            );
        }
    }
}
