import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { PanoCategory } from '../../core/models/pano-category.model';
import { SearchComponent } from '../search-modal/search/search.component';
import { isPlatformBrowser } from '@angular/common';
import { IMModalService } from '../modal/modal.service';
import { NativeActions } from '../../core/services/natif/native-actions';
import { WindowRef } from '../../core/utilities/window-ref/window-ref';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { isInDev } from '../../core/utilities/tools';
import { CypherLocalStore, FidjiAppConfigsManagerService, FidjiUserInfosWebService, FidjiUserManagerService } from '@immanens-com/fidjiapimodule2';
import { PanoCategoryControllerService } from '../../core/services/pano-category/pano-category-controller.service';

/**
 * Top bar shared by other components
 */
@Component({
    selector: 'app-top-bar',
    templateUrl: './top-bar.component.html',
    styleUrls: ['./top-bar.component.scss'],
    encapsulation: ViewEncapsulation.None // otherwise it seems we have conflicts with bootstrap
})
export class TopBarComponent implements OnInit, OnDestroy {

    @Input()
    public goBackButtonIsActive = false;

    @Input()
    public goBackUrl: string;

    @Input() currentCategory: string;

    public categories$: Observable<PanoCategory[]>;

    // public currentCategory: number;
    public currentRoute: string;

    public isLogged$ = this.userMng.connected$;
    public hasAdvSearchAccess = false;
    public isPremium$ = new BehaviorSubject(false);
    private subs = new Subscription();

    constructor(
        public userMng: FidjiUserManagerService,
        private categorieCtrl: PanoCategoryControllerService,
        private router: Router,
        private modalService: IMModalService,
        private nativeAction: NativeActions,
        private winRef: WindowRef,
        private userInfosWS: FidjiUserInfosWebService,
        public configsMng: FidjiAppConfigsManagerService
    ) { }

    ngOnInit() {
        this.categories$ = this.categorieCtrl.getCategoriesList();

        let offerRefId = 1206;
        const offerRefId2 = 1288;
        // if (isInDev()) {
        //     offerRefId = 1098;
        // }
        this.subs.add(
            this.userMng.connected$.subscribe(async connected => {
                if (connected) {
                    let i = 0;
                    while (!this.hasAdvSearchAccess && i < this.configsMng.getConfigValue('premium_store_id').length) {
                        const userInfo =
                            await this.userInfosWS.getUserInfos(this.configsMng.getConfigValue('premium_store_id')[i].id).toPromise();
                        if (userInfo.subscriptions
                            && userInfo.subscriptions.find(
                                sub => sub.offer.offer_id === offerRefId || (/*!isInDev() &&*/ sub.offer.offer_id === offerRefId2))
                        ) {
                            this.hasAdvSearchAccess = true;
                        }
                        i++;
                    }
                    if (!this.hasAdvSearchAccess) {
                        const userInfo =
                            await this.userInfosWS.getUserInfos(this.configsMng.getConfigValue('store_id').id).toPromise();
                        if (userInfo.subscriptions
                            && userInfo.subscriptions.find(
                                sub => sub.offer.offer_id === offerRefId
                                    || (!isInDev() && sub.offer.offer_id === offerRefId2))
                        ) {
                            this.hasAdvSearchAccess = true;
                        }
                    }
                }
            })
        );

        // we search if the USER is premium
        // in the same time we can know if the user has access to AFP
        this.subs.add(
            this.isLogged$.subscribe(async logged => {
                if (logged) {
                    let i = 0;
                    while (!this.isPremium$.getValue() && i < this.configsMng.getConfigValue('premium_store_id').length) {
                        const userInfo =
                            await this.userInfosWS.getUserInfos(this.configsMng.getConfigValue('premium_store_id')[i].id)
                                .toPromise();
                        if (userInfo.subscriptions && userInfo.subscriptions.find(sub => sub.active === true)) {
                            this.isPremium$.next(true);
                        }
                        i++;
                    }
                    if (!this.isPremium$.getValue()) {
                        const userInfo = await this.userInfosWS.getUserInfos(this.configsMng.getConfigValue('store_id').id)
                            .toPromise();
                        if (userInfo.subscriptions && userInfo.subscriptions.find(sub => sub.active === true)) {
                            this.isPremium$.next(false);
                        }
                    }
                }
            })
        );
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    /**
     * Return to the previous route
     */
    public clickGoBack() {
        if (this.goBackUrl !== '' && this.goBackUrl != null) {
            location.href = this.goBackUrl;
        } else {
            this.router.navigate(['home']);
        }
    }

    public clickOnCategory(category: PanoCategory) {
        const slug = category.slug ? category.slug : '';
        this.nativeAction.updateTab(slug);
        this.router.navigate(['category', slug, category.id]);
    }

    public clickOnSearch() {
        if (!this.hasAdvSearchAccess) {
            window.scrollTo(0, 0);
            this.modalService.init(SearchComponent, {}, {});
        } else {
            // if (isInDev()) {
            //     location.href = 'https://panorama-pro-dev.immanens.com'
            //         + '/advanced-search?x-user-token='
            //         + CypherLocalStore.getLocalKey('x-user-token').token;
            // } else { >>>> will be better to also set panopro on phenix prod
                location.href = 'https://pro.epresse.fr'
                    + '/advanced-search?x-user-token='
                    + CypherLocalStore.getLocalKey('x-user-token').token;
            // }
        }
    }

    public clickOnHome() {
        this.router.navigate(['home']);
    }

    public clickOnEpresse() {
        if (!this.winRef.nativeWindow.webkit && !this.winRef.nativeWindow.ePresseAndroid) {
            if (isInDev()) {
                location.href = 'https://beta.epresse.fr';
            } else {
                location.href = 'https://www.epresse.fr';
            }
        }
    }

    public clickOnSubscribe() {
        if (!this.winRef.nativeWindow.webkit && !this.winRef.nativeWindow.ePresseAndroid) {
            if (isInDev()) {
                location.href = 'https://beta.epresse.fr/nos-offres?callback_pano=' + encodeURIComponent(window.location.href.split('?')[0]);
            } else {
                location.href = 'https://www.epresse.fr/nos-offres?callback_pano=' + encodeURIComponent(window.location.href.split('?')[0]);
            }
        }
    }

    public clickOnConnect() {
        if (!this.winRef.nativeWindow.webkit && !this.winRef.nativeWindow.ePresseAndroid) {
            if (isInDev()) {
                location.href = 'https://beta.epresse.fr/connexion?url_callback=' + encodeURIComponent(window.location.href.split('?')[0]);
            } else {
                location.href = 'https://www.epresse.fr/connexion?url_callback=' + encodeURIComponent(window.location.href.split('?')[0]);
            }
        }
    }

    public clickOnAccount() {
        if (!this.winRef.nativeWindow.webkit && !this.winRef.nativeWindow.ePresseAndroid) {
            if (isInDev()) {
                location.href = 'https://beta.epresse.fr/mon-compte';
            } else {
                location.href = 'https://www.epresse.fr/mon-compte';
            }
        }
    }
}
