<ng-container *ngIf="extractList; else no_article_template">
  <section class="front-extract-grid">
    <article *ngFor="let article of (extractList | slice:0:1)"
      class="front-extract-grid-element front-extract-grid-element-left">
      <app-extract-thumbnail 
        [artExtract]="article" 
        [isFrontArticle]="false" 
        [isfirstFrontArticle]="true"
        [displayImage]="true"
      >
      </app-extract-thumbnail>
    </article>
    <article *ngFor="let article of (extractList | slice:1:2)"
      class="front-extract-grid-element front-extract-grid-element-right">
      <app-extract-thumbnail 
        [artExtract]="article" 
        [isFrontArticle]="false" 
        [isfirstFrontArticle]="true"
        [displayImage]="true"
      >
      </app-extract-thumbnail>
    </article>
  </section>
</ng-container>

<ng-template #no_article_template>
  <p>{{'detail.article.no-article' | translate}}</p>
</ng-template>
