import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CookieService} from 'ngx-cookie-service';
import { ArticleControllerService } from './services/article/article-controller.service';
import { PanoCategoryWebService } from './services/pano-category/pano-category-web.service';
import { ArticlePanoWebService } from './services/article/article-pano-web.service';
import { ArticlePV5WebService } from './services/article/article-pv5-web.service';
import { PV5TokenStore } from './services/token/store/token-pv5-store';
import { ConnectionGuard } from './guards/connection.guard';
import { NativeActions } from './services/natif/native-actions';
import { WindowRef } from './utilities/window-ref/window-ref';
import { DynamicScriptLoaderService } from './services/common/dynamic-loader.service';
import { FidjiTokenManagerService, FidjiUserInfosWebService } from '@immanens-com/fidjiapimodule2';
import { PanoCategoryControllerService } from './services/pano-category/pano-category-controller.service';
import { PreviousRouteService } from './services/previous-route/previous-route.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    CookieService,
    PanoCategoryControllerService,
    PanoCategoryWebService,
    ArticleControllerService,
    ArticlePanoWebService,
    ArticlePV5WebService,
    FidjiTokenManagerService,
    PV5TokenStore,
    ConnectionGuard,
    NativeActions,
    WindowRef,
    FidjiUserInfosWebService,
    DynamicScriptLoaderService,
    PreviousRouteService
  ]
})
export class CoreModule { }
