<header id="topbar" class="topbar">
  <section class="logo-bar" id="logo">
    <a class="epresse" (click)="clickOnEpresse()">
      <img src="/assets/images/logo_epresse_cafeyn.svg" alt="logo Epresse mode article" width="120">
    </a>
    <ul class="cta-buttons">
      <li>
        <button class="app-button" (click)="clickOnEpresse()">
          {{'navigation.kiosk' | translate}}
        </button>
      </li>
      <li *ngIf="!(isLogged$ | async)">
        <!--Actif quand pas connecté-->
        <button class="app-button app-button-secondary" (click)="clickOnConnect()">
          {{'account.login.login' | translate}}
        </button>
      </li>
      <li *ngIf="isLogged$ | async">
        <!--Actif connecté-->
        <!-- <button class="app-button app-button-secondary" (click)="clickOnAccount()">
          {{'navigation.account' | translate}}
        </button> -->
        <button class="nav-connected app-button app-button-secondary" (click)="clickOnAccount()">
            {{'navigation.account' | translate}} <i class="icon-profile"></i>
        </button>
      </li>
      <li *ngIf="!(isPremium$ | async) && !hasAdvSearchAccess">
        <button class="app-button" (click)="clickOnSubscribe()">
          {{'detail.article.subscribe' | translate | titlecase}}
        </button>
      </li>
    </ul>
  </section>
  <section class="menu">
    <ul id="menu" class="navmenu">
      <li>
        <p [routerLink]="['/home']" [routerLinkActive]="['current']" class="univers-button cat_1">
          {{'navigation.home-bis' | translate}}</p>
      </li>
      <ng-container *ngFor="let category of (categories$ | async)">
        <!-- NG IF DE TEST A SUPPRIMER PLUS TARD  -->
        <li *ngIf="category.name != 'A la une'">
          <p (click)="clickOnCategory(category)" class="univers-button cat_{{category.id}}"
            [ngClass]="{'current': (currentCategory == category.id && currentRoute != '/home' && currentRoute != '/search')}">
            {{category.name}}</p>
        </li>
      </ng-container>
      <li><button class="app-button app-button-tertiary" id="search-button"
          (click)="clickOnSearch()">{{'search.search' | translate}}</button>
      </li>
    </ul>
    <div id="menuToggle">
      <p><button class="search-button app-button" (click)="clickOnSearch()"><i
            class="icon-search"></i><span>{{'search.search' | translate}}</span></button></p>
      <p><i class="nav-arrow-icon icon-home home-button" title="{{'navigation.kiosk' | translate}}"
          [routerLink]="['/home']" [routerLinkActive]="['current']"></i>
      </p>
      <p *ngIf="userMng.connected$ | async"><i class="icon-profile" (click)="clickOnAccount()"></i></p>
      <input id="burger" class="burger" type="checkbox" />
      <label class="main-label" for="burger">
        <span></span>
        <span></span>
        <span></span>
      </label>
      <ul class="universe-content">
        <li>
          <ul class="cta-content">
            <li>
              <button class="app-button" (click)="clickOnEpresse()">
                {{'navigation.kiosk' | translate}}
              </button>
            </li>
            <li *ngIf="!(isPremium$ | async)">
              <button class="app-button" (click)="clickOnSubscribe()">
                {{'detail.article.subscribe' | translate | titlecase}}
              </button>
            </li>
            <li *ngIf="!(isLogged$ | async)">
              <!--Actif quand pas connecté-->
              <button class="app-button app-button-secondary" (click)="clickOnConnect()">
                {{'account.login.login' | translate}}
              </button>
            </li>
            <!-- <li *ngIf="userMng.connected$ | async"> -->
              <!--Actif connecté-->
              <!-- <button class="app-button app-button-secondary" (click)="clickOnAccount()">
                {{'navigation.account' | translate}}
              </button> -->
            <!-- </li> -->
          </ul>
        </li>
        <li>
          <ul class="menu-content">
            <li [ngClass]="{'current': currentCategory === 'home'}">
              <input id="burger_1" class="burger" type="checkbox" />
              <label for="burger" [routerLink]="['/home']"
                class="univers-button cat_1">{{'navigation.home-bis' | translate}}</label>
            </li>
            <ng-container *ngFor="let category of (categories$ | async)">
              <!-- NG IF DE TEST A SUPPRIMER PLUS TARD  -->
              <li *ngIf="category.name != 'A la une'"
                [ngClass]="{'current': (currentCategory == category.id && currentRoute != '/home' && currentRoute != '/search')}">
                <input id="burger_{{category.category_id}}" class="burger" type="checkbox" />
                <!-- <p (click)="clickOnCategorie($event)" class="univers-button cat_{{category.category_id}}"> -->
                <label for="burger" (click)="clickOnCategory(category)"
                  class="univers-button cat_{{category.category_id}}">{{category.name}}</label>
              </li>
            </ng-container>
          </ul>
        </li>
      </ul>
    </div>
  </section>
</header>
