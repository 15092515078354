<section class="afp-container" *ngIf="articleList.length > 0">
    <button class="left-button" [ngClass]="{'invisible': leftNavDisabled, 'visible': !leftNavDisabled}" (click)="moveLeft()"><i class="icon-arrow-left"></i></button>
    <div class="scrollable-content">
      <drag-scroll
          drag-scroll-y-disabled="true"
          scrollbar-hidden="true"
          #afpnav
          (reachesRightBound)="reachesRightBound($event)"
          (reachesLeftBound)="reachesLeftBound($event)">
        <section drag-scroll-item class="drag-scroll-item" *ngFor="let art of articleList">
          <div class="drag-scroll-item-container" (click)="onAFPArticleClick(art)">
            <div class="afp-thumb-img-container" *ngIf="art.pv5_article_extract_info.imageUrl">
                <app-lazy-image
                  class="afp-thumb-img"
                  [imageURL]=art.pv5_article_extract_info.imageUrl
                  [alt]=art.pv5_article_extract_info.title
                ></app-lazy-image>
                <img class="afp-thumb-logo logo" src="/assets/images/afp.png" alt="AFP" />
            </div>
            <img class="afp-thumb-full-logo logo" src="/assets/images/afp.png" alt="AFP" *ngIf="!art.pv5_article_extract_info.imageUrl"/>
            <div class="afp-thumb-text">
              <div class="afp-thumb-title" title="{{art.pv5_article_extract_info.title}}">
                {{art.pv5_article_extract_info.title | slice:0:75}}{{art.pv5_article_extract_info.title.length >= 75 ? '...' : ''}}
              </div>
              <div *ngIf="art.pano_article_info.issue.display_date_short == currentDate; else dateHorizontalTemplate" class="afp-thumb-date">{{'home.publication.afp-coverflow-live' | translate}} {{art.pano_article_info.issue.display_date | date:'H:mm':'':'fr'}}</div>
              <ng-template #dateHorizontalTemplate>
                <div class="afp-thumb-date">{{art.pano_article_info.issue.display_date | date:'d MMMM y, H:mm':'':'fr'}}</div>
              </ng-template>
            </div>
          </div>
        </section>
      </drag-scroll>
    </div>
    <div class="vertical-content" #afpverticalnav>
      <drag-scroll class="verticalscroll" scrollbar-hidden="true">
        <section class="verticalscroll-item" *ngFor="let art of articleList">
          <div class="verticalscroll-item-container" (click)="onAFPArticleClick(art)">
            <div class="afp-thumb-img-container" *ngIf="art.pv5_article_extract_info.imageUrl">
                <app-lazy-image
                  class="afp-thumb-img"
                  [imageURL]=art.pv5_article_extract_info.imageUrl
                  [alt]=art.pv5_article_extract_info.title
                ></app-lazy-image>
                <img class="afp-thumb-logo logo" src="/assets/images/afp.png" alt="AFP" />
            </div>
            <img class="afp-thumb-full-logo logo" src="/assets/images/afp.png" alt="AFP" *ngIf="!art.pv5_article_extract_info.imageUrl"/>
            <div class="afp-thumb-text">
              <div class="afp-thumb-title" title="{{art.pv5_article_extract_info.title}}">
                {{art.pv5_article_extract_info.title | slice:0:75}}{{art.pv5_article_extract_info.title.length >= 75 ? '...' : ''}}
              </div>
              <div *ngIf="art.pano_article_info.issue.display_date_short == currentDate; else dateVerticalTemplate" class="afp-thumb-date">{{'home.publication.afp-coverflow-live' | translate}} {{art.pano_article_info.issue.display_date | date:'H:mm':'':'fr'}}</div>
              <ng-template #dateVerticalTemplate>
                <div class="afp-thumb-date">{{art.pano_article_info.issue.display_date | date:'d MMMM y, H:mm':'':'fr'}}</div>
              </ng-template>
            </div>
          </div>
        </section>
      </drag-scroll>
      <button class="down-button" (click)="moveDown()"><i class="icon-arrow-down"></i></button>
    </div>
    <button class="right-button" [ngClass]="{'invisible': rightNavDisabled, 'visible': !rightNavDisabled}" (click)="moveRight()"><i class="icon-arrow-right"></i></button>
</section>