import {Component, ViewEncapsulation} from '@angular/core';
import { ExtractGridComponent } from '../extract-grid/extract-grid.component';

@Component({
  selector: 'app-front-extract-grid',
  templateUrl: './front-extract-grid.component.html',
  styleUrls: ['./front-extract-grid.component.scss'],
  encapsulation : ViewEncapsulation.None // otherwise it seems we have conflicts with bootstrap
})
export class FrontExtractGridComponent extends ExtractGridComponent {
}
