<section class="article-container-loading app-content" [@fadeInOut]="getFadeState()">
    <article class="header-item-loading">
        <div class="header loading" [ngStyle]="backgroundColorString()"></div>
    </article>
    <article class="main-item-loading">
        <div class="aside">
            <div *ngFor="let in of counter(10)" class="aside-element loading"  [ngStyle]="backgroundColorString()"></div>
        </div>
        <div class="main">
            <div class="article-main article-main-img loading" [ngStyle]="backgroundColorString()"></div>
            <div class="article-main article-main-title loading" [ngStyle]="backgroundColorString()"></div>
            <div class="article-main article-main-content loading" [ngStyle]="backgroundColorString()"></div>
        </div>
    </article>
</section>
