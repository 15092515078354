import { Component, EventEmitter, Input, Output, OnInit, Inject, OnDestroy } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { PanoCategory } from '../../../core/models/pano-category.model';
import { ArticleControllerService } from '../../../core/services/article/article-controller.service';
import { map, tap } from 'rxjs/operators';
import { PanoArticleExtract } from '../../../core/models/article.model';
import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';

@Component({
    selector: 'app-category-shelf',
    templateUrl: './category-shelf.component.html',
    styleUrls: ['./category-shelf.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CategoryShelfComponent implements OnInit, OnDestroy {

    // @Input() active = true;
    @Input() category: PanoCategory;
    @Input() offset = 0;
    @Input() nbArticles = 9;
    @Input() displaySeeMore = true;
    @Input() displayCatTitle = true;
    @Input() maxArticlesByCategory: number;
    @Input() withImage: number = null; // 0 for false, 1 for true
    @Input() isFrontCat = false;
    @Output() seeMoreClicked = new EventEmitter<PanoCategory>();

    public allCatArtExtracts$: Observable<PanoArticleExtract[]>;
    public hasMore: Observable<boolean> = new Observable<false>(); // has more than 9 articles
    public artAreLoading$ = new BehaviorSubject(true);

    public maxArticlesByCategoryDefault = 27;

    public isTablet = false;
    public nbByRow = 3;

    private subscriptions = new Subscription();

    constructor(private articleCtrl: ArticleControllerService) { }

    ngOnInit() {
        this.isTablet = (window.innerWidth <= 768);

        if (this.isTablet) {
            this.nbArticles = 8;
            this.maxArticlesByCategoryDefault = 26;
            this.nbByRow = 2;
        }

        const maxArt = (this.maxArticlesByCategory !== undefined ? this.maxArticlesByCategory : this.maxArticlesByCategoryDefault);

        this.allCatArtExtracts$ = this.articleCtrl.getArticleExtractsByCategory(this.category.id, maxArt, this.withImage).pipe(
            map(list => list.extracts),
            tap(_ => this.artAreLoading$.next(false))
        );

        this.subscriptions.add(this.allCatArtExtracts$.subscribe());

        this.hasMore = this.allCatArtExtracts$.pipe(
            map(list => this.category.weight === 1 ? list.length > 6 : (list.length > this.nbArticles))
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    public emitSeeMoreCliked(cat) {
        this.seeMoreClicked.next(cat);
    }

}
