import { Input, Directive } from '@angular/core';

@Directive()
export abstract class AbstractLoadingComponent {

  @Input() primaryColor = '#ececec';
  @Input() secondaryColor = '#c7c7c7';
  @Input() speed = '15000';
  @Input() fadeIn = true;

  constructor() { }

  backgroundColorString = () => {
    const style = {
     'background-image': `linear-gradient(-90deg,${this.primaryColor},${this.secondaryColor},${this.primaryColor},${this.secondaryColor})`,'animation': `gradient ${this.speed}ms ease infinite`
    };
    return style;
  }

  getFadeState() {
    return !this.fadeIn ? 2 : 1;
  }

}
