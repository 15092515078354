import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { FidjiAppSettingsManagerService, FidjiNetworkManagerService } from '@immanens-com/fidjiapimodule2';
import { map, pairwise, take, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable()
export class ConnectionGuard implements CanActivate, CanActivateChild {

    constructor(
        protected network: FidjiNetworkManagerService,
        protected appsettings: FidjiAppSettingsManagerService,
        protected router: Router
    ) { }

    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        // Here we just check state and return true immediatly
        // This is because we don't want to block the transition between two page in case of a slow network
        // See app.component.ts for more
        return this.checkAndRedirectConnection();
    }

    canActivateChild(): Observable<boolean> | Promise<boolean> | boolean {
        return this.checkAndRedirectConnection();
    }

    checkAndRedirectConnection(): Observable<boolean> {
        if (!this.appsettings.appSettings) {
            // Some time, check connection but in true, no setting, so in this case, go to load-config component
            this.router.navigate(['load-configs']);
            return of(false);
        }
        this.network.checkIsOnlineState();
        return this.network.isOnlineChange.pipe(
            pairwise(),
            take(1),
            map(([wasOnline, isOnline]) => isOnline),
            tap((isOnline) => {
                if (!isOnline) {
                    this.router.navigate(['load-configs']);
                }
            }),
        );
    }

}
