import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observer, Observable} from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { FidjiAppConfigsManagerService, HTTPMethods, objectIsEmpty } from '@immanens-com/fidjiapimodule2';


/** All Panorama API request are anonymous **/
export interface PanoRequest {
    observer: Observer<any>;
    url: string;
    body?: object;
    method: HTTPMethods;
}

@Injectable()
export class AbstractPanoWebService {

    protected requestsQueue: PanoRequest[] = [];

    protected requestsArePending = true; // We don't launch requests on init.

    constructor(protected http: HttpClient,
                protected sanitizer: DomSanitizer,
                protected configsMng: FidjiAppConfigsManagerService
    ) {
        this.relaunchService();
    }

    protected relaunchService() {
        if (this.allIsOkForService()) {
            this.requestsArePending = false;
            this.continueLaunchingRequests();
        }
    }

    private allIsOkForService(): boolean {
        return this.requestsArePending === true;
    }

    /**
     * This function prepare the request and add it to the requestQueue
     */
    protected get(route: string, parameters: object = null): Observable<any> {

        const url = this.constructURL(route, parameters);

        return Observable.create((observer: Observer<any>) => {
            const request = {
                url: url,
                observer: observer,
                method: HTTPMethods.GET
            };

            this.requestsQueue.push(request);

            if (this.requestsArePending === true) {
                this.relaunchService();
            }

            return;

        });
    }

    /**
     * This function prepare the request and add it to the requestQueue
     */
    protected post(route: string, body: object = null): Observable<any> {

        const url = this.constructURL(route, null);

        return Observable.create((observer: Observer<any>) => {
            const request = {
                url: url,
                observer: observer,
                body: body,
                method: HTTPMethods.POST
            };

            this.requestsQueue.push(request);

            if (this.requestsArePending === true) {
                this.relaunchService();
            }

            return;
        });
    }

    /**
     * This function have a good name :)
     */
    private constructURL(route: string, parameters: object): string {

        let finalURL = this.configsMng.getConfigValue('pano_api_URL') + route;

        if (!objectIsEmpty(parameters)) {

            finalURL += '?';

            for (const key of Object.keys(parameters)) {

                const value = parameters[key];

                if (parameters[key] != null) { // Avoid null or undefined parameters

                    if (Array.isArray(parameters[key])) {
                        value.forEach((v) => {
                            finalURL += (finalURL.substr(-1) === '?' ? '' : '&') + `${key}[]=${v}`;
                        });
                    } else {
                        finalURL += (finalURL.substr(-1) === '?' ? '' : '&') + `${key}=${parameters[key]}`;
                    }
                }
            }
        }

        return finalURL;
    }

    /**
     * Recursive function.
     * Stop when requestArePending is true. Relaunch by tokenIsSetChange observer.
     * This function get the first entry of the RequestQueue array and verify that all the context is good for launching request
     * if no problems are detected, we launch the request and move to the next request in the queue
     */
    private async continueLaunchingRequests() {
        while (this.requestsArePending === false) { // it's a deamon !
            if (this.requestsQueue.length === 0) {
                this.requestsArePending = true;
                return;
            }

            const request = this.requestsQueue.shift(); // Get the first element of the array
            this.launchRequest(request); // Launch request and continue
        }
    }

    /**
     * Launch request with current token and appropriate headers and complete
     * Manage errors with manageErrors method
     */
    private async launchRequest(request: PanoRequest) {

        const httpOptions = {
            headers: new HttpHeaders()
        };

        if (request.method === HTTPMethods.GET) {
            return this.http
                .get(request.url, httpOptions)
                .subscribe((data) => request.observer.next(data),
                    error => this.manageErrors(error, request),
                    () => request.observer.complete());
        }

        if (request.method === HTTPMethods.POST) {
            return this.http
                .post(request.url, request.body, httpOptions)
                .subscribe((data) => request.observer.next(data),
                    error => this.manageErrors(error, request),
                    () => request.observer.complete());
        }
    }

    private manageErrors(error: HttpErrorResponse, request: PanoRequest): void {
        if (error.status === 401 || error.status === 403) { // Unauthorized
            request.observer.error(error);
            return;
        } else if (error.status === 405 || error.status === 404) { // Not Found
            request.observer.error(error); // request is drop
            return;
        } else if (error.status === 500 || error.status === 540) { // Serveur malfunction
            request.observer.error(error); // send error
            return;
        } else if (error.status === 0 || error.status === 408 || error.status === 410) { // No server connection or time-out
            this.requestsArePending = true;
            return;
        } else {
            request.observer.error(error); // send unknown error
        }
    }
}
