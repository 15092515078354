import { Component, Input, OnInit } from '@angular/core';
import { AbstractLoadingComponent } from '../abstract-loading.component';
import { trigger, state, style, transition, animate } from '@angular/animations';

export const UIAnimations = {
    FadeInOut: trigger('fadeInOut', [
        state('0',
            style({ opacity: 0 })
        ),
        state('2',
            style({ opacity: 100 })
        ),
        state('void',
            style({ opacity: 0 })
        ),
        state('1',
            style({ opacity: 100 })
        ),
        transition('void <=> 0', animate(0)),
        transition('void <=> 2', animate(0)),
        transition('0 <=> 1', animate(250)),
        transition('void <=> 1', animate(250)),
    ])
};

@Component({
    selector: 'app-loading-grid',
    templateUrl: './loading-grid.component.html',
    styleUrls: ['./loading-grid.component.scss'],
    animations: [
        UIAnimations.FadeInOut
    ]
})
export class LoadingGridComponent extends AbstractLoadingComponent {

    @Input() set length(value) {
        this.array = Array(value).fill(1);
    }
    @Input() height = '';
    @Input() isFrontCat = false;

    array: Array<number>;

    constructor() {
        super();
        this.length = 5;
    }
}
