import { Component, Input, ViewEncapsulation, ViewChild, ElementRef, Inject, OnInit } from '@angular/core';
import { PanoArticleExtract } from '../../../core/models/article.model';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { Router } from '@angular/router';
import { NativeActions } from '../../../core/services/natif/native-actions';
import { isPlatformBrowser } from '@angular/common';
import { WindowRef } from '../../../core/utilities/window-ref/window-ref';

@Component({
    selector: 'app-afp-coverflow',
    templateUrl: './afp-coverflow.component.html',
    styleUrls: ['./afp-coverflow.component.scss'],
    encapsulation: ViewEncapsulation.None // otherwise it seems we have conflicts with bootstrap
})
export class AFPCoverflowComponent implements OnInit {

    // @Input() active = true;
    @Input() articleList: PanoArticleExtract[];
    leftNavDisabled = false;
    rightNavDisabled = false;
    date = new Date;
    currentDate: string;

    @ViewChild('afpnav', { read: DragScrollComponent }) afpNavScroll: DragScrollComponent;
    @ViewChild('afpverticalnav') afpverticalnav: ElementRef;

    constructor(private router: Router,
        private nativeAction: NativeActions,
        private winRef: WindowRef
    ) {}

    ngOnInit() {
        this.currentDate = this.generateDate();
    }

    moveLeft() {
        this.afpNavScroll.moveLeft();
    }

    moveRight() {
        this.afpNavScroll.moveRight();
    }

    moveDown() {
        const afpSportScrollableDiv: Element = this.afpverticalnav.nativeElement.getElementsByClassName('drag-scroll-content').item(0);
        afpSportScrollableDiv.scrollTop += 70;
    }
    reachesLeftBound(reachesLeftBound: boolean) {
        this.leftNavDisabled = reachesLeftBound;
    }

    reachesRightBound(reachesRightBound: boolean) {
        this.rightNavDisabled = reachesRightBound;
    }

    onAFPArticleClick(afpArt) {
        const slug = afpArt.pano_article_info.slug ? afpArt.pano_article_info.slug : '';
        this.nativeAction.openArticle(
            window.location.protocol + '//' + window.location.host
            + '/article/' + slug + '/' + afpArt.pano_article_info.id
            + '/' + afpArt.pano_article_info.issue.number + '/' + afpArt.pano_article_info.issue.puc
            + '/afp',
            afpArt.pano_article_info.issue.publication_title ? afpArt.pano_article_info.issue.publication_title : '',
            afpArt.pv5_article_extract_info.title,
            afpArt.pv5_article_extract_info.extract
        );

        if (!this.winRef.nativeWindow.webkit && !this.winRef.nativeWindow.ePresseAndroid) {
            this.router.navigate(['article',
                slug,
                afpArt.pano_article_info.id,
                afpArt.pano_article_info.issue.number,
                afpArt.pano_article_info.issue.puc,
                'afp'
            ]);
        }
    }

    generateDate() {
      const y = this.date.getFullYear().toString();
      const month = this.date.getMonth() + 1;
      const day = this.date.getDate();
      let m = '';
      let d = '';

      month < 10 ? m = '0' + month.toString() : m = month.toString();
      day < 10 ? d = '0' + day.toString() : d = day.toString();

      return y + m + d;
    }
}
