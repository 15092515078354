import { Component, Input, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { WindowRef } from '../../core/utilities/window-ref/window-ref';

@Component({
    selector: 'app-lazy-image',
    template: `
    <img *ngIf="!isOldiOS"
        [defaultImage]="defaultImage"
        [lazyLoad]="imageURL"
        alt="{{alt}}"
    />
    <img *ngIf="isOldiOS"
        src="{{imageURL}}"
        alt="{{alt}}"
    />
  `
})
export class LazyImageComponent {
    defaultImage = '/assets/images/epresse-default.png';
    @Input() imageURL = '';
    @Input() alt = '';

    public isOldiOS = false;

    constructor(private winRef: WindowRef) {

        const testIfApple = /iPad|iPhone|iPod/;
        const testOS = /OS\s([0-9]+)(_[0-9]+)*/;
        if (testIfApple.test(this.winRef.nativeWindow.window.navigator.userAgent)) {
            const foundOS = this.winRef.nativeWindow.window.navigator.userAgent.match(testOS);
            if (foundOS) {
                this.isOldiOS = foundOS[1] < 12;
            }
        }
    }
}
