import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { filter, map, switchMap } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { PanoArticle, Pv5ArticleExtract, Pv5ArticleExtracts } from '../../models/article.model';
import { AbstractPV5WebService } from '../abstract-pv5-web.service';
import { Observable, of } from 'rxjs';
import { FidjiAppSettingsManagerService, FidjiIssueWebService, FidjiStoreShopManagerService } from '@immanens-com/fidjiapimodule2';
@Injectable()
export class ArticlePV5WebService extends AbstractPV5WebService {

    constructor(
        protected http: HttpClient,
        protected sanitizer: DomSanitizer,
        private issueWS: FidjiIssueWebService,
        private storeShop: FidjiStoreShopManagerService,
        private settings: FidjiAppSettingsManagerService
    ) {
        super(http, sanitizer, settings);
    }

    public getExtractForArticles(articles: PanoArticle[], isAFP = false): Observable<Pv5ArticleExtract[]> {
        const ids = [];

        articles.map(art => {
            // if (isAFP) {
            //   pv5Id = art.issue.puc + '_' + art.issue.number + '_' + art.external_article_id.replace('doc-', '');
            // }
            ids.push(art.issue.puc + '_' + art.issue.number + '_' + art.id);
            return ids;
        });

        if (ids.length === 0) {
            return of([]);
        }

        return this.doGetExtracts(ids);
    }

    public getExtract(art_id: number, issue_number: number, puc_issue: number) {
        const ids = [];
        ids.push(puc_issue + '_' + issue_number + '_' + art_id);

        return this.doGetExtracts(ids).pipe(
            map(list => list[0])
        );
    }

    private doGetExtracts(ids) {
        return this.get('/entity-extract', { 'ids': ids }).pipe(
            map((list: Pv5ArticleExtracts) => list.entities),
            map(entities => {
                entities.forEach((ent: Pv5ArticleExtract) => {
                    if (ent) {
                        ent.imageUrl = this.getArticleExtractImage(ent);
                    }
                });
                return entities;
            }
            )
        );
    }

    public getArticleExtractImage(artExtract: Pv5ArticleExtract) {
        // https://pv5web-panorama-dev.immanens.com/api/document/123/456/xmlv3/image-html/1_40_k2_k1_60.jpg

        // pv5 article extract id = puc_number_panoId
        if (artExtract.imageName !== undefined && artExtract.imageName !== null) {
            const pv5ArtExtractIds = artExtract.id.split('_');
            return this.settings.appSettings.pv5_api_url
                + '/document/' + pv5ArtExtractIds[0] + '/' + pv5ArtExtractIds[1] + '/xmlv3/image-html/' + artExtract.imageName;
        } else {
            return '';
        }
    }

    public getArticlePreview(art_id: number, issue_number: number, puc_issue: number): Observable<string> {
        // /api/document/:publicationId/:documentId/xmlv3/entity-html-preview/:entityId
        // get(route: string, parameters: object = null, anonymous = true, token = null, responseType = 'json')
        return this.get('/document/' + puc_issue + '/' + issue_number + '/xmlv3/entity-html-preview/' + art_id + '',
            { 'format': 'json', 'theme_version': 'head' }).pipe(
                map(jsonresponse => {
                    return jsonresponse.html;
                })
            );
    }

    public getArticleContent(art_id: number, issue_number: number, puc_issue: number, store = null): Observable<string> {
        this.storeShop.selectShopStore(store);
        return this.issueWS.getArticleStreamingToken(art_id, puc_issue, issue_number).pipe(
            switchMap(token => {
                return this.get('/document/' + puc_issue + '/' + issue_number + '/xmlv3/entity-html/' + art_id + '',
                    { 'format': 'json', 'theme_version': 'head' }, false, token).pipe(
                        map(jsonresponse => {
                            return jsonresponse.html;
                        })
                    );
            })
        );
    }

    public getAFPArticleContent(art_id: number, issue_number: number, puc_issue: number, store = null): Observable<string> {
        this.storeShop.selectShopStore(store);
        return this.issueWS.getAFPStreamingToken(issue_number).pipe(
            switchMap(token => {
                return this.get('/document/' + puc_issue + '/' + issue_number + '/xmlv3/entity-html/' + art_id + '',
                    { 'format': 'json', 'theme_version': 'head' }, false, token).pipe(
                        map(jsonresponse => {
                            return jsonresponse.html;
                        })
                    );
            })
        );
    }
}
