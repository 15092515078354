<ng-container *ngIf="!isfirstFrontArticle && !isFrontArticle; else frontarticle_template">
    <section class="art-extract-thumb" (click)="onArticleClick()">
        <div class="art-thumb-top" *ngIf="artExtract.pv5_article_extract_info.imageUrl">
            <app-lazy-image *ngIf="displayImage"
              class="art-thumb-img" 
              [imageURL]=artExtract.pv5_article_extract_info.imageUrl
              [alt]=artExtract.pv5_article_extract_info.title
              ></app-lazy-image>
            <app-lazy-image
              class="art-thumb-logo logo" 
              [ngClass]="{'ontop': displayImage}"
              [imageURL]=pubLogo
              [alt]=artExtract.pano_article_info.issue.panorama_publication_title
              ></app-lazy-image>
            <!-- <img class="art-thumb-logo logo" [ngClass]="{'ontop': displayImage}" src="{{pubLogo}}" alt="{{artExtract.pano_article_info.issue.panorama_publication_title}}" />  -->
        </div>
        <div class="art-thumb-top" *ngIf="!artExtract.pv5_article_extract_info.imageUrl && !customDefaultImage">
            <app-lazy-image *ngIf="!displayImage"
              class="art-thumb-logo logo" 
              [imageURL]=pubLogo
              [alt]=artExtract.pv5_article_extract_info.title
            ></app-lazy-image>
            <app-lazy-image *ngIf="displayImage"
                class="art-thumb-img logo-default" 
                [imageURL]=pubLogo
                [alt]=artExtract.pv5_article_extract_info.title
              ></app-lazy-image>
        </div>
        <div class="art-thumb-top" *ngIf="!artExtract.pv5_article_extract_info.imageUrl && customDefaultImage">
            <app-lazy-image *ngIf="displayImage"
              class="art-thumb-img custom-default" 
              [imageURL]=customDefaultImage
              [alt]=artExtract.pv5_article_extract_info.title
            ></app-lazy-image>
      </div>
      <p class="art-title">{{artExtract.pv5_article_extract_info.title}}</p>
      <p class="art-date">{{artExtract.pano_article_info.issue.display_date | date:'d MMMM y':'':'fr'}}</p>        
      <p class="art-text" *ngIf="showText">{{artExtract.pv5_article_extract_info.extract | slice:0:240}}...</p>
    </section>
</ng-container>

<ng-template #frontarticle_template>
  <section (click)="onArticleClick()" class="front-art-thumb row" *ngIf="isFrontArticle && !isfirstFrontArticle; else firstfrontarticle_template">
      <article class="col-md-3 col-5 art-thumb-left" *ngIf="artExtract.pv5_article_extract_info.imageUrl">
          <app-lazy-image *ngIf="displayImage"
            class="art-thumb-img" 
            [imageURL]=artExtract.pv5_article_extract_info.imageUrl
            [alt]=artExtract.pv5_article_extract_info.title
          ></app-lazy-image>
          <app-lazy-image
            class="art-thumb-logo logo" 
            [ngClass]="{'ontop': displayImage}"
            [imageURL]=pubLogo
            [alt]=artExtract.pano_article_info.issue.panorama_publication_title
          ></app-lazy-image>
          <!-- <img class="art-thumb-logo logo" [ngClass]="{'ontop': displayImage}" src="{{pubLogo}}" />  -->
      </article>
      <div class="col-md-3 col-5 art-thumb-left" *ngIf="!artExtract.pv5_article_extract_info.imageUrl && displayImage">
          <app-lazy-image *ngIf="displayImage"
            class="art-thumb-img logo-default" 
            [imageURL]=pubLogo
            [alt]=artExtract.pv5_article_extract_info.title
          ></app-lazy-image>
      </div>
      <article class="col-md-9 col-7 art-thumb-right" >
        
        <!-- <img *ngIf="!artExtract.pv5_article_extract_info.imageUrl" class="art-thumb-logo logo" [ngClass]="{'ontop': displayImage}" src="{{pubLogo}}" /> -->
        <p class="art-title">{{artExtract.pv5_article_extract_info.title}}</p>
        <p class="art-date">{{artExtract.pano_article_info.issue.display_date | date:'d MMMM y':'':'fr'}}</p>
      </article>
    </section>

  <ng-template #firstfrontarticle_template>
    <section (click)="onArticleClick()" class="first-front-art-thumb">
      <div class="art-thumb-top" *ngIf="artExtract.pv5_article_extract_info.imageUrl">
          <app-lazy-image *ngIf="displayImage"
            class="art-thumb-img" 
            [imageURL]=artExtract.pv5_article_extract_info.imageUrl
            [alt]=artExtract.pv5_article_extract_info.title
          ></app-lazy-image>
          <app-lazy-image 
            class="art-thumb-logo logo" 
            [ngClass]="{'ontop': displayImage && artExtract.pv5_article_extract_info.imageUrl}"
            [imageURL]=pubLogo
            [alt]=artExtract.pano_article_info.issue.panorama_publication_title
          ></app-lazy-image>
          <!-- <img class="art-thumb-logo logo" [ngClass]="{'ontop': displayImage && artExtract.pv5_article_extract_info.imageUrl}" src="{{pubLogo}}" />  -->
      </div>
      <div class="art-thumb-top" *ngIf="!artExtract.pv5_article_extract_info.imageUrl">
        <app-lazy-image 
          class="art-thumb-img logo-default"
          [imageURL]=pubLogo
          [alt]=artExtract.pv5_article_extract_info.title
        ></app-lazy-image>
        <!-- <img class="art-thumb-img logo-default" src="{{pubLogo}}" alt="{{artExtract.pv5_article_extract_info.title}}" /> -->
      </div>
      <p class="art-title">{{artExtract.pv5_article_extract_info.title}}</p>
      <p class="art-date">{{artExtract.pano_article_info.issue.display_date | date:'d MMMM y':'':'fr'}}</p>      
      <p class="art-text" *ngIf="showText">{{artExtract.pv5_article_extract_info.extract | slice:0:90}}...</p>
    </section>
  </ng-template>
</ng-template>
