<ng-container *ngIf="displayCatTitle && isFrontCat">
    <h1 class="cat-shelf-title a-la-une">{{category.name}}</h1>
</ng-container>
<ng-container *ngIf="displayCatTitle && !isFrontCat">
    <h2 class="cat-shelf-title">{{category.name}}</h2>
</ng-container>
<ng-container *ngIf="!isFrontCat; else frontCat_template">
  <ng-container *ngIf="!(artAreLoading$|async); else articles_loading">
    <ng-container *ngIf="(allCatArtExtracts$ | async)?.length > 0; else nodoc_template">
      <app-extract-grid [extractList]="(allCatArtExtracts$ | async) | slice:0:nbByRow" [isImageGrid]="true"></app-extract-grid>
    </ng-container>
    <ng-container *ngIf="(allCatArtExtracts$ | async)?.length > nbByRow">
      <app-extract-grid [extractList]="(allCatArtExtracts$ | async) | slice:nbByRow:nbArticles"></app-extract-grid>
      <div class="app-seemore app-seemore-tertiary" *ngIf="(hasMore | async) && displaySeeMore" (click)="emitSeeMoreCliked(category)">{{'home.category.show-more' | translate}}</div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #nodoc_template>
  <p class="text-center">{{'detail.article.no-article' | translate}}</p>
</ng-template>

<ng-template #frontCat_template>
  <ng-container *ngIf="!(artAreLoading$|async); else frontCat_articles_loading">
    <section class="front-article-grid" *ngIf="(allCatArtExtracts$ | async)?.length > 0; else nodoc_template">
      <app-front-extract-grid [extractList]="(allCatArtExtracts$ | async) | slice:0:2"></app-front-extract-grid>
      <div class="app-seemore" *ngIf="(hasMore | async) && displaySeeMore" [routerLink]="['/category/actualite/1']">{{'home.category.show-more' | translate}}</div>
    </section>
  </ng-container>
</ng-template>

<ng-template #articles_loading>
  <app-loading-grid
    [length]="3"
    [height]="'large'">
  </app-loading-grid>
  <app-loading-grid
    [length]="6"
    [height]="'medium'">
  </app-loading-grid>
</ng-template>
<ng-template #frontCat_articles_loading>
  <app-loading-grid
    [length]="9"
    [height]="'imageFrontCat'"
    [isFrontCat]="true">
  </app-loading-grid>
</ng-template>
