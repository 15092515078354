import {Component, ViewEncapsulation} from '@angular/core';

/**
 * Top bar shared by other components
 */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation : ViewEncapsulation.None // otherwise it seems we have conflicts with bootstrap
})
export class FooterComponent {
}
