import { Component, Input, OnInit, Inject } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { PanoArticleExtract } from '../../../core/models/article.model';
import { Router } from '@angular/router';
import { NativeActions } from '../../../core/services/natif/native-actions';
import { isPlatformBrowser } from '@angular/common';
import { WindowRef } from '../../../core/utilities/window-ref/window-ref';

@Component({
    selector: 'app-extract-thumbnail',
    templateUrl: './extract-thumbnail.component.html',
    styleUrls: ['./extract-thumbnail.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ExtractThumbnailComponent implements OnInit {

    @Input() artExtract: PanoArticleExtract;
    @Input() displayImage = false;
    @Input() isfirstFrontArticle = false;
    @Input() isFrontArticle = false;
    @Input() showText = true;
    @Input() customDefaultImage = '';

    public pubLogo = '/assets/images/epresselogo.png';

    constructor(private router: Router,
        private nativeAction: NativeActions,
        private winRef: WindowRef) { }

    public ngOnInit() {
        if (this.artExtract.pano_article_info.issue.publication_image) {
            this.pubLogo = this.artExtract.pano_article_info.issue.publication_image;
        }
    }

    public onArticleClick() {
        const slug = this.artExtract.pano_article_info.slug ? this.artExtract.pano_article_info.slug : '';
        this.nativeAction.openArticle(
            window.location.protocol + '//' + window.location.host
            + '/article/' + slug + '/' + this.artExtract.pano_article_info.id
            + '/' + this.artExtract.pano_article_info.issue.number + '/' + this.artExtract.pano_article_info.issue.puc
            + '/' + this.artExtract.pano_article_info.issue.id,
            this.artExtract.pano_article_info.issue.publication_title ? this.artExtract.pano_article_info.issue.publication_title : '',
            this.artExtract.pv5_article_extract_info.title,
            this.artExtract.pv5_article_extract_info.extract
        );

        if (!this.winRef.nativeWindow.webkit && !this.winRef.nativeWindow.ePresseAndroid) {
            this.router.navigate(['article',
                slug,
                this.artExtract.pano_article_info.id,
                this.artExtract.pano_article_info.issue.number,
                this.artExtract.pano_article_info.issue.puc,
                this.artExtract.pano_article_info.issue.id
            ]);
        }
    }
}
