import { Component, Input, ViewEncapsulation } from '@angular/core';
import { PanoArticleExtract } from '../../../core/models/article.model';

@Component({
    selector: 'app-extract-grid',
    templateUrl: './extract-grid.component.html',
    styleUrls: ['./extract-grid.component.scss'],
    encapsulation: ViewEncapsulation.None // otherwise it seems we have conflicts with bootstrap
})
export class ExtractGridComponent {

    @Input() extractList: PanoArticleExtract[];
    @Input() isImageGrid = false;
    @Input() showText = true;
    @Input() customDefaultImage = '';
}
