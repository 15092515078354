<section class="extractList" *ngIf="extractList; else no_article_template">
  <article *ngFor="let article of extractList" class="extractList-element">
    <app-extract-thumbnail
      [artExtract]="article"
      [displayImage]="isImageGrid"
      [showText]="showText"
      [customDefaultImage]="customDefaultImage"></app-extract-thumbnail>
  </article>
</section>

<ng-template #no_article_template>
  <p>{{'detail.article.no-article' | translate}}</p>
</ng-template>