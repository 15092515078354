import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { FidjiAppConfigsManagerService, FidjiAppSettingsManagerService, FidjiNetworkManagerService } from '@immanens-com/fidjiapimodule2';
import { ConnectionGuard } from './connection.guard';

@Injectable({
    providedIn: 'root'
})
/**
 * Check if application is mono publication (go to detail component in this case)
 */
export class AppConfigsGuard implements CanActivate, CanActivateChild {

    constructor(
        private appConfigs: FidjiAppConfigsManagerService,
        protected appsettings: FidjiAppSettingsManagerService,
        protected network: FidjiNetworkManagerService,
        protected router: Router,
        private connectionGuard: ConnectionGuard
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.checkAppConfigs(state);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.checkAppConfigs(state);
    }

    async checkAppConfigs(state: RouterStateSnapshot) {
        if (this.appConfigs.appConfigs) {
            return true;
        }
        // if config is not loaded yet, go wait for it
        // check why no config :
        // may be no network (in this case, redirect doing in checkAndRedirectConnection)
        const isOnline = await this.connectionGuard.checkAndRedirectConnection();
        if (isOnline) {
            // connection ok, just wait for get confing
            this.savePreviousUrl(state);
            this.router.navigate(['/load-configs']);
        }
        return false;
    }

    private savePreviousUrl(state: RouterStateSnapshot): void {
        const queryParams = { ...state.root.queryParams };
        delete queryParams.appid;
        localStorage.setItem(
            'previous_url', JSON.stringify(
                { path: state.url.split('?')[0], queryParams: queryParams }
            )
        );
    }
}
