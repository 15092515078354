import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { ConnectionGuard } from './core/guards/connection.guard';
import { NoConnectionComponent } from './no-connection/no-connection.component';
import { AppConfigsGuard } from './core/guards/app-configs.guard';
import { AppLoadConfigsComponent } from './app-load-configs.component';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: '/home'
    },
    {
        path: '',
        canActivate: [AppConfigsGuard],
        children: [
            {
                path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
                canActivateChild: [ConnectionGuard]
            },
            {
                path: 'article', loadChildren: () => import('./article/article.module').then(m => m.ArticleModule),
                canActivateChild: [ConnectionGuard]
            },
            {
                path: 'category', loadChildren: () => import('./category/category.module').then(m => m.CategoryModule),
                canActivateChild: [ConnectionGuard]
            },
            {
                path: 'top', loadChildren: () => import('./top-articles/top-articles.module').then(m => m.TopArticlesModule),
                canActivateChild: [ConnectionGuard]
            },
            {
                path: 'viewer', loadChildren: () => import('./viewer/viewer.module').then(m => m.ViewerModule),
                canActivateChild: [ConnectionGuard]
            },
            {
                path: 'search-viewer', loadChildren: () => import('./viewer/viewer.module').then(m => m.ViewerModule),
                canActivateChild: [ConnectionGuard]
            },
            {
                path: 'advanced-search',
                loadChildren: () => import('./search-advanced/search-advanced.module').then(m => m.SearchAdvancedModule),
                canActivateChild: [ConnectionGuard]
            },
            { path: 'no-connection', component: NoConnectionComponent },
            { path: 'not-found', component: NotFoundComponent },
        ]
    },
    { path: 'load-configs', component: AppLoadConfigsComponent },

    { path: '**', redirectTo: '/not-found' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'enabled',
        preloadingStrategy: PreloadAllModules
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
