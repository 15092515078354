import { Component, ElementRef, OnInit, ViewEncapsulation, Inject, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DynamicScriptLoaderService } from '../../../core/services/common/dynamic-loader.service';
import { Subscription } from 'rxjs';
import { FidjiAppConfigsManagerService, FidjiSearchPV5WebService } from '@immanens-com/fidjiapimodule2';

declare var $: any;

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    encapsulation: ViewEncapsulation.None // otherwise it seems we have conflicts with bootstrap
})
export class SearchComponent implements OnInit, OnDestroy {

    private subs = new Subscription();

    constructor(
        private searchWS: FidjiSearchPV5WebService,
        private elementRef: ElementRef,
        private datePipe: DatePipe,
        private scriptService: DynamicScriptLoaderService,
        public configsMng: FidjiAppConfigsManagerService
    ) { }

    async ngOnInit() {
        await this.scriptService.load('themerepo-epresse', 'reader-press', 'search-press');
        const token = await this.searchWS.getSearchToken();
        const limitDaysOld = new Date();
        limitDaysOld.setDate(limitDaysOld.getDate() - 40);

        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.innerHTML = `new immanens.pv5.SearchPress({
                            el: "#search-content",
                            pv5api: "${this.configsMng.getConfigValue('search_pv5_required_settings.pv5PressApi')}",
                            token: "${token.token}",
                            lang: "fr",
                            themeApi: "https://theme-repo.immanens.com/api",
                            theme: "spr-base",
                            themeVersion:"head",
                            searchMode: "entity",
                            onResultActionClicked:function(search,action,result_model, query_model){
                              var page = 1;
                              if(result_model.number) page = result_model.number[0];
                              else if(result_model.page) page = result_model.page[0];
                              var more = query_model ?  {query: {allTerms: query_model.allTerms}} : {};
                              if(result_model.entityId) more.entityId = result_model.entityId;
                              var url = "search-viewer/"+result_model.publicationId+"/"+result_model.documentId+"/"+page
                                        + "/" + btoa(JSON.stringify(more));
                              window.open(url, "viewer", "", true);
                            },
                            useRouter:false,
                            changeWindowTitle: false,
                            searchAdvanced: false,
                            showTooltip: false,
                            minPublishedDate:"${this.datePipe.transform(limitDaysOld, 'yyyy-MM-dd')}",
                            maxPublishedDate:"${this.datePipe.transform(new Date(), 'yyyy-MM-dd')}",
                            defaultSort: "date-desc"
                          });`;
        this.elementRef.nativeElement.appendChild(s);

        this.onElementInserted(document.body, '.pv5sp_b-query__all-terms-input', function (element) {
            element.placeholder = 'Mots clés, thèmes, ...';
        });

        this.onElementInserted(document.body, '.pv5sp_b-entity-results-info', function (element) {
            $(element).text('');
        });

        this.onElementInserted(document.body, '.pv5sp_b-query__all-terms-input', function (element) {
            $(element).focus();
        });
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    // https://stackoverflow.com/questions/10415400/jquery-detecting-div-of-certain-class-has-been-added-to-dom
    private onElementInserted(containerSelector, elementSelector, callback) {
        const onMutationsObserved = function (mutations) {
            mutations.forEach(function (mutation) {
                if (mutation.addedNodes.length) {
                    const elements = $(mutation.addedNodes).find(elementSelector);
                    for (let i = 0, len = elements.length; i < len; i++) {
                        callback(elements[i]);
                    }
                }
            });
        };

        const target = $(containerSelector)[0];
        const config = { childList: true, subtree: true };
        const observer = new MutationObserver(onMutationsObserved);
        observer.observe(target, config);
    }

}
