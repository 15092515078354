import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter, HostListener, OnDestroy } from '@angular/core';
import { PanoTocItemsBySection, PanoIssue, PanoTocItem } from '../../../core/models/article.model';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ArticleControllerService } from '../../../core/services/article/article-controller.service';
import { map, tap } from 'rxjs/operators';
import { WindowRef } from '../../../core/utilities/window-ref/window-ref';
import { NativeActions } from '../../../core/services/natif/native-actions';

@Component({
    selector: 'app-issue-toc',
    templateUrl: './issue-toc.component.html',
    styleUrls: ['./issue-toc.component.scss'],
    encapsulation: ViewEncapsulation.None // otherwise it seems we have conflicts with bootstrap
})
export class IssueTocComponent implements OnInit, OnDestroy {

    public tocItemsBySection: Observable<PanoTocItemsBySection[]>;
    @Input() issue: PanoIssue;

    public maxArticlesDisplay: number;

    private tocContainerHeight = new BehaviorSubject<number>(this.vh(80) - 312);
    private tocContainerHeightObs = this.tocContainerHeight.asObservable();
    private oneTocItemHeight = 30;
    private subs = new Subscription();

    constructor(
        private router: Router,
        private articleCtrl: ArticleControllerService,
        private winRef: WindowRef,
        private nativeAction: NativeActions) { }

    ngOnInit() {
        this.tocItemsBySection = this.articleCtrl.getIssueSummary(this.issue.id).pipe(
            map((items) => {
                /*
                we need to group articles by section
                input :
                  items: [{
                    // item1
                    section:'aaa',
                    id:'...',
                    ...
                  },{
                    // item2
                    ...
                  }];

                  output :
                  a: [{
                      section: 'aaa'
                      items: [{item1}, {item2}...]
                    }]
                */
                const offsets = {}; // = {sectionName: offset in groupedItems, ...}
                return items.reduce((groupedItems, oneItem) => {
                    const sectionName = oneItem.section.charAt(0).toUpperCase() + oneItem.section.slice(1).toLowerCase();
                    if (offsets[sectionName] === undefined) {
                        // sectionName unknown => new offset
                        offsets[sectionName] = groupedItems.length;
                        // push new empty entry that will have this offset
                        groupedItems.push({
                            section: sectionName,
                            items: []
                        });
                    }
                    // push the items in the right group, at the offset offsets[sectionName]
                    groupedItems[offsets[sectionName]].items.push(oneItem);
                    return groupedItems;
                }, []);
            })
        );
        this.subs.add(
            this.tocContainerHeightObs.subscribe(height => {
                const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
                if (w > 991) { // if not mobile
                    // document.getElementById('toc-container').style.height = height + 'px ';
                    document.getElementById('toc-container').style.maxHeight = height + 'px ';
                }
            })
        );
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.tocContainerHeight.next(this.vh(80) - 312);
    }

    private vh(v) {
        const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        return (v * h) / 100;
    }

    public onTocItemClicked(tocitem) {

        this.nativeAction.openArticle(
            window.location.protocol + '//' + window.location.host
            + '/article/' + (tocitem.slug !== undefined ? tocitem.slug : '') + '/' + tocitem.panorama_article_id.toString()
            + '/' + this.issue.number + '/' + this.issue.puc
            + '/' + this.issue.id,
            this.issue.publication_title ? this.issue.publication_title : '',
            tocitem.title,
            tocitem.preview
        );

        this.router.navigate(['article',
            tocitem.slug !== undefined ? tocitem.slug : '',
            tocitem.panorama_article_id,
            this.issue.number,
            this.issue.puc,
            this.issue.id
        ]);

    }
}
