import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FidjiAppConfigsManagerService, FidjiAppSettingsManagerService } from '@immanens-com/fidjiapimodule2';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-load-configs',
    template: '',
    styleUrls: []
})
export class AppLoadConfigsComponent implements OnInit, OnDestroy {

    private subs: Subscription = new Subscription();
    private isReady = false;

    constructor(
        private appConfigs: FidjiAppConfigsManagerService,
        private appSettings: FidjiAppSettingsManagerService,
        private router: Router
    ) {
        // wait for setting to be loaded
        this.subs.add(
            // in hybrid application, get setting is delayed,
            // may be guard routing to this component because no setting
            // if setting ok, re-route to previousURL
            this.appSettings.appSettingsChange.subscribe(settings => {
                if (settings) {
                    this.isReady = true;
                }
            })
        );
    }
    ngOnInit(): void {
        const previousURL = JSON.parse(
            localStorage.getItem('previous_url')
        );

        // wait for configs to be loaded
        this.subs.add(
            this.appConfigs.appConfigsChange.subscribe(isAppConfigReady => {
                if (isAppConfigReady && this.isReady) {
                    if (previousURL?.path) {
                        this.router.navigate([previousURL.path], { queryParams: previousURL.queryParams });
                    } else {
                        this.router.navigate(['home']);
                    }
                }
            })
        );
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
        // localStorage.removeItem('previous_url');
    }
}
