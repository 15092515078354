import { Injectable } from '@angular/core';
import { PanoArticle, PanoArticleList, Pv5ArticleExtract, PanoArticleExtractList, PanoTocItem } from '../../models/article.model';
import { map, share, switchMap } from 'rxjs/operators';
import { ArticlePanoWebService } from './article-pano-web.service';
import { ArticlePV5WebService } from './article-pv5-web.service';
import { Observable, combineLatest } from 'rxjs';
import { Tools } from '../../utilities/tools';
@Injectable()
export class ArticleControllerService {

    constructor(private articlePanoWS: ArticlePanoWebService,
        private articlePV5WS: ArticlePV5WebService) { }

    public getArticlesByCategory(cat_id: number, limit: number = 0, has_image: number = null, sort: string = '', desc: string = '')
        : Observable<PanoArticleList> {
        return this.articlePanoWS.getArticlesByCategory(cat_id, limit, has_image, sort, desc).pipe(
            map(list => {
                list.articles.forEach((elt: PanoArticle) => {
                    if (elt) {
                        elt.issue.category_id = cat_id;
                    }
                });
                return list;
            }
            ),
            share()
        );
    }

    public getArticleExtractsByCategory(cat_id: number, limit: number = 0, has_image: number = null, sort: string = '', desc: string = '')
        : Observable<PanoArticleExtractList> {

        const panoArts: Observable<PanoArticleList> = this.getArticlesByCategory(cat_id, limit, has_image, sort, desc);

        return this.combineWithPv5(panoArts);
    }

    public getAFPArticleExtracts(limit: number = 0): Observable<PanoArticleExtractList> {
        const panoArts: Observable<PanoArticleList> = this.articlePanoWS.getAFPArticles(limit).pipe(
            map(list => {
                const articles = list.articles;
                articles.map(art => {
                    art.issue = {
                        puc: 99999,
                        number: art.id,
                        display_date: art.date_added,
                        display_date_short: art.date_added.split('T')[0].replace(/-/gi, '')
                    };
                    art.id = art.external_article_id.replace('doc-', '');
                    return art;
                });
                return list;
            }),
            share()
        );
        return this.combineWithPv5(panoArts, true);
    }

    public getAFPSportArticleExtracts(limit: number = 0): Observable<PanoArticleExtractList> {
        const panoArts: Observable<PanoArticleList> = this.articlePanoWS.getAFPSportArticles(limit).pipe(
            map(list => {
                const articles = list.articles;
                articles.map(art => {
                    art.issue = {
                        puc: 99999,
                        number: art.id,
                        display_date: art.date_added,
                        display_date_short: art.date_added.split('T')[0].replace(/-/gi, '')
                    };
                    art.id = art.external_article_id.replace('doc-', '');
                    return art;
                });
                return list;
            }),
            share()
        );
        return this.combineWithPv5(panoArts, true);
    }

    public getArticlesByIssue(issue_id: number, limit: number = 0, offset: number = 0): Observable<PanoArticleExtractList> {

        const panoArts: Observable<PanoArticleList> = this.articlePanoWS.getArticlesByIssue(issue_id, limit, offset).pipe(
            share()
        );

        return this.combineWithPv5(panoArts);
    }

    public getArticlesByPhenixIssue(issue_id: number, limit: number = 0, offset: number = 0): Observable<PanoArticleExtractList> {

        const panoArts: Observable<PanoArticleList> = this.articlePanoWS.getArticlesByPhenixIssue(issue_id, limit, offset).pipe(
            share()
        );

        return this.combineWithPv5(panoArts);
    }

    public getIssueSummary(issue_id: number): Observable<PanoTocItem[]> {
        return this.articlePanoWS.getIssueSummary(issue_id).pipe(
            share()
        );
    }

    public getArticlePV5Extract(art_id: number, issue_number: number, puc_issue: number): Observable<Pv5ArticleExtract> {
        return this.articlePV5WS.getExtract(art_id, issue_number, puc_issue).pipe(
            share()
        );
    }

    public getArticlePreview(art_id: number, issue_number: number, puc_issue: number) {
        return this.articlePV5WS.getArticlePreview(art_id, issue_number, puc_issue);
    }

    public getArticleContent(art_id: number, issue_number: number, puc_issue: number, store = null) {
        return this.articlePV5WS.getArticleContent(art_id, issue_number, puc_issue, store).pipe(
            share()
        );
    }

    public getAFPArticleContent(art_id: number, issue_number: number, puc_issue: number, store = null) {
        return this.articlePV5WS.getAFPArticleContent(art_id, issue_number, puc_issue, store).pipe(
            share()
        );
    }

    private combineWithPv5(panoArts: Observable<PanoArticleList>, isAFP = false) {
        const pv5Extracts: Observable<Pv5ArticleExtract[]> = panoArts.pipe(
            switchMap(list => {
                return this.articlePV5WS.getExtractForArticles(list.articles, isAFP);
            }
            )
        );

        return combineLatest([panoArts, pv5Extracts]).pipe(
            map(([pa, pv]) => {
                return {
                    pagination: pa.pagination,
                    extracts: pa.articles.map(art => {
                        const extract: Pv5ArticleExtract =
                            pv.find((onepv) => (art.issue.puc + '_' + art.issue.number + '_' + art.id === onepv.id)
                                && onepv.found);
                        if (extract) {
                            art.slug = extract.title ? Tools.slugify(extract.title) : art.id;
                            return {
                                'pv5_article_extract_info': extract,
                                'pano_article_info': art
                            };
                        }
                    })
                        .filter((elt) => elt !== null && elt !== undefined)
                };
            }),
            share()
        );
    }
}
