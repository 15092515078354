import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {PanoCategory, PanoCategoryList} from '../../models/pano-category.model';
import {HttpClient} from '@angular/common/http';
import { AbstractPanoWebService } from '../abstract-pano-web.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FidjiAppConfigsManagerService } from '@immanens-com/fidjiapimodule2';
@Injectable()
export class PanoCategoryWebService extends AbstractPanoWebService {

  constructor(protected http: HttpClient,
      protected sanitizer: DomSanitizer,
      protected configsMng: FidjiAppConfigsManagerService
  ) {
    super(http, sanitizer, configsMng);
  }

  public categoriesList(limit: number = 0, params: {[key: string]: any} = {}): Observable<PanoCategoryList> {
    if (limit > 0) {
        params.with_publications_limit = limit;
    }

    return this.get('/category', params);
  }

  public getCategory(id: number): Observable<PanoCategory> {
    return this.get('/category/' + id);
  }
}
