
import { Injectable } from '@angular/core';
import { CypherLocalStore, TokenFactory } from '@immanens-com/fidjiapimodule2';

@Injectable()
export class PV5TokenStore {

    public static readonly PV5_TOKEN_STORAGE_KEY = 'x-pv5-token';

    /** Token storage **/

    public saveToken(token: string): boolean {
        if (!TokenFactory.tokenStringIsValid(token)) { return false; }
        this.setLocalToken(token);

        return true;
    }

    public clearLocalToken() {
        return CypherLocalStore.clearLocalKey(PV5TokenStore.PV5_TOKEN_STORAGE_KEY);
    }

    public getLocalToken(): string {
        return CypherLocalStore.getLocalKey(PV5TokenStore.PV5_TOKEN_STORAGE_KEY) as string;
    }

    private setLocalToken(token: string): void {
        CypherLocalStore.setLocalKey(PV5TokenStore.PV5_TOKEN_STORAGE_KEY, token);
    }

}
