import { Injectable } from '@angular/core';

interface Scripts {
  name: string;
  type: string;
  src: string;
  loaded: boolean;
}

// https://stackoverflow.com/questions/44204417/dynamically-load-external-javascript-file-from-angular-component
// https://medium.com/better-programming/angular-load-external-javascript-file-dynamically-3d14dde815cb
// https://stackoverflow.com/questions/34489916/how-to-load-external-scripts-dynamically-in-angular

export const ScriptStore: Scripts[] = [
  {name: 'themerepo-epresse', type: 'css', src: 'https://theme-repo.immanens.com/api/theme/rxe-epresse/style?version=head', loaded: false},
  {name: 'themerepo-epresse-preview', type: 'css',
    src: 'https://theme-repo.immanens.com/api/theme/rxe-epresse-preview/style?version=head', loaded: false},
  {name: 'reader-press', type: 'javascript', src: 'https://pressview5.immanens.com/api/app/reader-press/js', loaded: false},
  {name: 'search-press', type: 'javascript', src: 'https://pressview5.immanens.com/api/app/search-press/js', loaded: false},
  {name: 'search-press-dev', type: 'javascript', src: 'https://pv5web-imm-dev.immanens.com/api/app/search-press/js', loaded: false}
];

declare var document: any;

@Injectable({
  providedIn: 'root'
})
export class DynamicScriptLoaderService {

  private scripts: any = {};

  constructor() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: script.loaded,
        src: script.src,
        type: script.type
      };
    });
  }

  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadFile(script)));
    return Promise.all(promises);
  }

  loadFile(name: string) {
    return new Promise((resolve, reject) => {
      if (!this.scripts[name].loaded) {
        // load file according to type
        let file;
        if (this.scripts[name].type === 'javascript') {
            file = document.createElement('script');
            file.type = 'text/javascript';
            file.src = this.scripts[name].src;
        }
        if (this.scripts[name].type === 'css') {
          file = document.createElement('link');
          file.rel = 'stylesheet';
          file.type = 'text/css';
          file.href = this.scripts[name].src;
      }

        if (file.readyState) { // IE
            file.onreadystatechange = () => {
                if (file.readyState === 'loaded' || file.readyState === 'complete') {
                    file.onreadystatechange = null;
                    this.scripts[name].loaded = true;
                    resolve({script: name, loaded: true, status: 'Loaded'});
                }
            };
        } else {  // Others
            file.onload = () => {
                this.scripts[name].loaded = true;
                resolve({script: name, loaded: true, status: 'Loaded'});
            };
        }
        file.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
        document.getElementsByTagName('head')[0].appendChild(file);
      } else {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      }
    });
  }
}
