<footer id="footer">
  <div class="footer-container">
    <section class="download">
      <h3 class="title">Télécharger l'application ePresse.fr</h3>
      <ul>
        <li class="appstore"><a class="btn-app" href="https://itunes.apple.com/fr/app/epresse/id430832375?mt=8">App Store</a></li>
        <li class="googleplay"><a class="btn-app" href="https://play.google.com/store/apps/details?id=fr.epresse.android&amp;hl=fr">Google Play</a></li>
      </ul>
    </section>
  
    <section class="foot-infos">
        <div class="foot-infos-col apropos desktop">
          <h3 class="title">À propos</h3>
          <ul>
            <li><a href="https://www.epresse.fr//cgv">CGV</a></li>
            <li><a href="https://www.epresse.fr//politique-de-confidentialite">Politique de confidentialité</a></li>
            <li><a href="mailto:partenaires@epresse.fr">Être diffusé sur ePresse</a></li>
            <li><a href="mailto:presse@epresse.fr">Relation Presse</a></li>
          </ul>
        </div>

        <div class="foot-infos-col services desktop">
          <h3 class="title">Services</h3>
          <ul>
            <li><a href="https://www.epresse.fr//aide">Aide en ligne</a></li>
            <li><a href="mailto:support@epresse.fr">support@epresse.fr</a></li>
            <li><a href="mailto:support@epresse.fr">Contact</a></li>
          </ul>
        </div>

        <div class="foot-infos-col reseaux">
          <h3 class="title">Réseaux sociaux</h3>
          <ul class="socials">
            <li class="facebook"><a href="https://www.facebook.com/pages/ePresse/185455604842300"><i class="icon-facebook"></i><!-- Suivez-nous sur Facebook --></a></li>
            <li class="twitter"><a href="https://twitter.com/ePressefr"><i class="icon-twitter"></i><!-- Suivez-nous sur Twitter --></a></li>
            <!-- <li class="google"><a href="https://www.google.com/+EpresseFr">Suivez-nous sur Google +</a></li> -->
          </ul>
        </div>

        <div class="foot-infos-col-mob apropos mobile">
          <h3 class="title">Gardons le contact</h3>
          <ul>
            <li><a href="mailto:support@epresse.fr">Contact</a></li>
            <li><a href="mailto:presse@epresse.fr">Relation Presse</a></li>
            <li><a href="mailto:partenaires@epresse.fr">Être diffusé sur ePresse</a></li>
          </ul>
        </div>
    </section>
  
    <section class="legal">
        <p>&copy; Copyright 2019 — ePresse.fr</p>  
        <ul>
          <li><a href="https://www.epresse.fr//politique-de-confidentialite">Respect de la vie privée</a></li>
          <li><a href="https://www.epresse.fr//cgv">Conditions Générales de Vente</a></li>
        </ul>
    </section>
  </div>
</footer>